/**
 * TODO refactor with HeaderSupplier in library
 */

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import styled from "styled-components";
import * as PropTypes from "prop-types";

const HeaderContainer = styled.div`
  display: inline-block;
  width: 100%;  
  height: auto;
  max-width: 300px;
  //flex-grow: 1;
`;

/**
 * Returns one random game-header.
 * Uses GatsbyImage and GraphQL to load images.
 * @see https://www.orangejellyfish.com/blog/a-comprehensive-guide-to-images-in-gatsby/
 */
const HeaderSupplier = ({ gameHeader }) => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: {
        extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
        sourceInstanceName: {eq: "game-header"}}) 
      {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
              originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }`);
  // console.log(gameHeader);
  // console.log(allFile.edges);

  const image = allFile.edges.find(
    element => element.node.childImageSharp.fluid.originalName === gameHeader,
  );
  const fluid = image.node.childImageSharp.fluid;

  return (
    <HeaderContainer>
      <GatsbyImage fluid={fluid} alt={fluid.originalName} />
    </HeaderContainer>
  );
};
HeaderSupplier.propTypes = {
  gameHeader: PropTypes.string.isRequired,
};

export default HeaderSupplier;
