import React from "react";
import * as PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { OutboundLinkNoStyle } from "../../navigation/ExternalLink";
import iconEpic from "../../../res/branded/store-icon/epic.min.svg";
import iconSteam from "../../../res/branded/store-icon/steam.min.svg";
import iconNintendo from "../../../res/branded/store-icon/nintendo-switch.min.svg";
import iconPlaystation from "../../../res/branded/store-icon/playstation.min.svg";
import iconHumble from "../../../res/branded/store-icon/humble.min.svg";
import iconXbox from "../../../res/branded/store-icon/xbox.min.svg";
import oberionTheme from "../../element/theme";

const stores = {
  discord: {
    buttonCaption: "Discord",
    icon: iconSteam,
    color: "",
  },
  epic: {
    buttonCaption: "Epic",
    icon: iconEpic,
    color: "#58595D",
  },
  gog: {
    buttonCaption: "GOG",
    icon: iconSteam,
    color: "#7C2198",
  },
  humble: {
    buttonCaption: "Humble Store",
    icon: iconHumble,
    color: "#A1A0FE",
  },
  nintendo: {
    buttonCaption: "Nintendo",
    icon: iconNintendo,
    color: "",
  },
  playstation: {
    buttonCaption: "Playstation",
    icon: iconPlaystation,
    color: "",
  },
  steam: {
    buttonCaption: "Steam",
    icon: iconSteam,
    color: "#145D90",
  },
  xbox: {
    buttonCaption: "Xbox",
    icon: iconXbox,
    color: "",
  },
};

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: "#1B0A41",
    // borderBottom: props => props.borderBottom,
    borderBottom: props => `solid 3px ${props.color} !important`,
    padding: "0.3rem 0.5rem",
    textTransform: "none",
    margin: `0.3rem ${theme.spacing(2)}px 0 0`,
    width: "8.5rem",
    "&:hover": {
      backgroundColor: oberionTheme.palette.primary.main,
    },
  },
  leftIcon: {
    height: "1.5rem",
    marginRight: theme.spacing(1),
  },
}));

const StoreButton = ({ store, storeHref }) => {
  const color = stores[store].color;
  const classes = useStyles({ color });
  let storeIcon = null;
  if (stores[store].icon !== null) {
    storeIcon = (
      <img src={stores[store].icon}
           alt={stores[store].buttonCaption}
           className={classes.leftIcon} />
    );
  }

  return (
    <OutboundLinkNoStyle href={storeHref} key={store}>
      <Button variant="outlined" size="small" className={classes.button}>
        {storeIcon}
        {stores[store].buttonCaption}
      </Button>
    </OutboundLinkNoStyle>
  );
};
StoreButton.propTypes = {
  store: PropTypes.string.isRequired,
  storeHref: PropTypes.string.isRequired,
};

export default StoreButton;