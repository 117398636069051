import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";

const Icon = styled.img`
    height: 1.3rem;
    border-radius: 50%;
    margin: 0.05rem 0 0 0.1rem;
`;

const IconSupplier = ({ icon, caption }) => {
  return (
    <Icon
      src={icon}
      alt={caption}
    />
  );
};

IconSupplier.propTypes = {
  icon: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
};

export default IconSupplier;