import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import RatingCoins from "../../element/graphical/RatingCoins";
import { oberionBlue1 } from "../../element/theme";


/** @see http://www.cssarrowplease.com/ */
const ArrowBox = styled.div`
  max-width: 17rem;
  position: relative;
  background: ${oberionBlue1};
  margin-top: 1rem;
  padding: 0.5rem 0.75rem;
  &:after {
    bottom: 100%;
    left: 23%;
    border: solid transparent 0.5rem;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: ${oberionBlue1};
    margin-left: -0.5rem;
  }
`;

const RatingTooltip = ({ userRating, score, ratings }) => {
  return (
    <div>
      <RatingCoins userRating={userRating} />
      <ArrowBox>{`${score}% positive based on ${ratings} ratings`}</ArrowBox>
    </div>
  );
};
RatingTooltip.propTypes = {
  userRating: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
  ratings: PropTypes.number.isRequired,
};
export default RatingTooltip;