import React, { Component } from "react";
import * as PropTypes from "prop-types";
import LayoutColumn from "../components/element/layout/LayoutColumn";
import GamePage from "../components/markup/GamePage";
import CustomLayout from "../components/markup/CustomLayout";
import Layout from "../components/element/layout/Layout";

class NewSite extends Component {
  render() {
    return (
      <Layout>
        <LayoutColumn>
          <GamePage />
        </LayoutColumn>
      </Layout>
    );
  }
}

NewSite.propTypes = {};

export default NewSite;