import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import Footer from "../navigation/Footer";
import Headroom from "react-headroom";
import { Logo } from "./CustomLogo";

const PageContainer = styled.div`
  height: 100%;
  min-height: calc(100vh);
  
  display: flex; 
  flex-direction: column;
`;

/**
 * Fundamental layout which provides Header and Footer in a column style.
 */
const CustomLayout = ({ children }) => {
  return (
    <PageContainer>
      <Headroom>
        <Logo caption={"Celeste"} />
      </Headroom>

      {children}

      <Footer />
    </PageContainer>
  );
};
CustomLayout.defaultProps = {
  showSideSvg: false,
};
CustomLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showSideSvg: PropTypes.bool,
};
export default CustomLayout;