import React from "react";
import IndexPage from "../../pages";

const AboutTheGame = () => {
  return (
    <div className="game_page_autocollapse_ctn expanded">
      <div className="game_page_autocollapse"
           // style="max-height: none;"
      >
        <div className="game_area_description" id="game_area_description">
          <h2>About This Game</h2>
          Stardew Valley is an open-ended country-life RPG! <br /><br />
          <img
            src="https://steamcdn-a.akamaihd.net/steam/apps/413150/extras/animalStrip2.png?t=1574808568" />
          <br /><br />
          You've inherited your grandfather's old farm plot in Stardew Valley. Armed with
          hand-me-down
          tools and a few coins, you set out to begin your new life. Can you learn to live off the
          land and turn these overgrown fields into a thriving home? It won't be easy. Ever since
          Joja Corporation came to town, the old ways of life have all but disappeared. The
          community center, once the town's most vibrant hub of activity, now lies in shambles.
          But the valley seems full of opportunity. With a little dedication, you might just be
          the one to restore Stardew Valley to
          greatness!<br /><br /><strong>Features </strong><br />
          <ul className="bb_ul">
            <li><strong>Turn your overgrown field into a lively farm! </strong> Raise animals,
              grow crops, start an orchard, craft useful machines, and more! You'll have
              plenty of space to create the farm of your dreams. <br /><br /></li>
            <li><strong>4 Player Farming!</strong> Invite 1-3 players to join you in the valley
              online! Players can work together to build a thriving farm, share resources, and
              improve the local community. As more hands are better than one, players have the
              option to scale profit margin on produce sold for a more challenging
              experience.<br /><br />
            </li>
            <li><strong>Improve your skills over time. </strong> As you make your way from a
              struggling greenhorn to a master farmer, you'll level up in 5 different areas:
              farming, mining, combat, fishing, and foraging. As you progress, you'll learn
              new cooking and crafting recipes, unlock new areas to explore, and customize
              your skills by choosing from a variety of professions. <br /><br /></li>
            <li><strong>Become part of the local community. </strong> With over 30 unique
              characters living in Stardew Valley, you won't have a problem finding new
              friends! Each person has their own daily schedule, birthday, unique
              mini-cutscenes, and new things to say throughout the week and year. As you make
              friends with them, they will open up to you, ask you for help with their
              personal troubles, or tell you their secrets! Take part in seasonal festivals
              such as the luau, haunted maze, and feast of the winter star. <br /><br /></li>
            <li><strong>Explore a vast, mysterious cave. </strong> As you travel deeper
              underground, you'll encounter new and dangerous monsters, powerful weapons, new
              environments, valuable gemstones, raw materials for crafting and upgrading
              tools, and mysteries to be uncovered. <br /><br /></li>
            <li><strong>Breathe new life into the valley.</strong> Since JojaMart opened, the
              old way of life in Stardew Valley has changed. Much of the town's infrastructure
              has fallen into disrepair. Help restore Stardew Valley to it's former glory by
              repairing the old community center, or take the alternate route and join forces
              with Joja Corporation.<br /><br /></li>
            <li><strong>Court and marry a partner to share your life on the farm with. </strong>
              There are 12 available bachelors and bachelorettes to woo, each with unique
              character progression cutscenes. Once married, your partner will live on the
              farm with you. Who knows, maybe you'll have kids and start a family?<br /><br />
            </li>
            <li><strong>Spend a relaxing afternoon at one of the local fishing spots. </strong>
              The waters are teeming with seasonal varieties of delicious fish. Craft bait,
              bobbers, and crab pots to help you in your journey toward catching every fish
              and becoming a local legend! <br /><br /></li>
            <li><strong>Donate artifacts and minerals to the local museum. </strong><br /><br />
            </li>
            <li><strong>Cook delicious meals and craft useful items to help you out. </strong>With
              over 100 cooking and crafting recipes, you'll have a wide variety of items to
              create. Some dishes you cook will even give you temporary boosts to skills,
              running speed, or combat prowess. Craft useful objects like scarecrows, oil
              makers, furnaces, or even the rare and expensive crystalarium. <br /><br /></li>
            <li><strong>Customize the appearance of your character and house. </strong> With
              hundreds of decorative items to choose from, you'll have no trouble creating the
              home of your dreams!<br /><br /></li>
            <li><strong>Xbox controller support (with rumble)! (Keyboard still required for text
              input) </strong><br /><br /></li>
            <li><strong>Over two hours of original music. </strong></li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default AboutTheGame;
