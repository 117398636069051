import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import Rating from "@material-ui/lab/Rating";
import coin from "../../markup/game-preview/visual/rating-coin-OberionIO.min.svg";
import emptyCoin from "../../markup/game-preview/visual/rating-coin-empty-OberionIO.min.svg";

const RatingDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 1.1rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
`;
const RatingImg = styled.img`
  margin: 0.1rem 0.15rem;
`;

function getLabelText(value) {
  return `${value} Heart${value !== 1 ? "s" : ""}`;
}

const RatingCoins = ({ userRating }) => {
  const coinsRating = (
    <Rating
      name="customized-color"
      value={userRating}
      getLabelText={getLabelText}
      precision={0.5}
      icon={<RatingImg src={coin} />}
      emptyIcon={<RatingImg src={emptyCoin} />}
      readOnly
    />
  );
  return (<RatingDiv>{coinsRating}</RatingDiv>);
};
RatingCoins.propTypes = {
  userRating: PropTypes.number.isRequired,
};
export default RatingCoins;
