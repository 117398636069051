import React from "react";
import * as PropTypes from "prop-types";
import AboutTheGame from "./AboutTheGame";
import styled from "styled-components";
import { contentMaxWidthText } from "../element/theme";
import GamePreview from "./game-preview/GamePreview-Celeste";
import { data } from "./game-preview/data-celeste";
import Typography from "@material-ui/core/Typography";
import Pricing from "./Pricing";

const AboutTheGameDiv = styled.div`
  max-width: ${contentMaxWidthText}rem;
`;

const GamePage = props => {
  return (
    <>
      <Typography variant="h2">Your site preview</Typography>
      <GamePreview game={data.games[0]} />
      <AboutTheGameDiv>
        <AboutTheGame />
      </AboutTheGameDiv>
      <Pricing />
    </>
  );
};

GamePage.propTypes = {};

export default GamePage;