import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import efeuLeft from "./visual/golden-efeu-left-OberionIO.min.svg";
import efeuRight from "./visual/golden-efeu-right-OberionIO.min.svg";
import goldenStar from "./visual/golden-star-OberionIO.min.svg";

const EfeuImg = styled.img`
  margin: 0 0.2rem;
  height: 2.5rem;
`;
const StarImg = styled.img`
  margin: 0 0.2rem;
  height: 2rem;
`;
const AwardDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 1rem;
`;

const ivy = caption => (
  <AwardDiv>
    <EfeuImg src={efeuLeft} alt="Ivy to the left" />
    <Typography variant="caption" align="center">{caption}</Typography>
    <EfeuImg src={efeuRight} alt="Ivy to the right" />
  </AwardDiv>
);
const star = caption => (
  <AwardDiv>
    <StarImg src={goldenStar} alt="Golden Star" />
    <Typography variant="caption" align="center">{caption}</Typography>
  </AwardDiv>
);


const Award = ({ type, caption }) => {
  switch (type) {
    case "ivy":
      return ivy(caption);
    case "star":
      return star(caption);
    default:
      return star(caption);
  }
};
Award.defaultProps = {
  staffPick: false,
};
Award.propTypes = {
  staffPick: PropTypes.bool,
};
export default Award;