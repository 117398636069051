import React from "react";
import styled from "styled-components";
import { ROUTES } from "../navigation/routes";
import { InternalRoute } from "../navigation/InternalRoute";
import OoAvatar from "../element/graphical/OoAvatar";
import thomas from "../element/graphical/thomas-uta-avatar-500px-lowres.jpg";

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = ({caption}) => {
  return (
    <InternalRoute caption={caption} to={ROUTES.HOME.TO}
                   variant="h2"
                   lightTheme="header" />
  );
};
