/** @see https://www.styled-components.com/docs/advanced#media-templates */
import { css } from "styled-components";
import theme from "./theme";

const breakpoints = { ...theme.breakpoints.values };
/**
 * If [device width] is greater than or equal to xs, then do {…}
 * ${minMedia.xs``};
 */
export const greaterOrEqualThan = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
/**
 * If [device width] is smaller than or equal to xs, then do {…}
 * ${maxMedia.xs``};
 */
export const lessOrEqualThan = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
